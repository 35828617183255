<template>
  <div id="app" :class="[lang, isAgencyAgentLogin && 'has-background']" :style="{backgroundColor: backgroundColor}">
    <agency-login v-if="isAgencyLoginState"/>
    <ContentLoading v-if="isAgency && !isFetchedAllData && !isProcessingBooking && !isAgencyLoginState" type="app" />
    <Container v-if="isFetchedAllData && isActive && isAgency && !isLanding && !isLandingChain && !isAgencyLoginState" />
    <NoRegisterAgency v-if="!isAgency && !isLanding && !isLandingChain" />
    <LandingPage v-if="(isLanding || isLandingChain) && landingInfo" />
    <b-modal
      id="processingBooking"
      centered
      size="lg"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true"
      :hide-footer="true"
      :hide-header="currentPage==='product'"
    >
      <template #toast-title>
        <div class="d-flex flex-grow-1 align-items-baseline" :dir="lang==='he' ? 'rtl' : 'ltr'">
          <h3>{{ $t('booking.waiting') }}</h3>
        </div>
      </template>
      <div class="text-center" v-if="currentPage==='product'">
        <img class="product" src="/assets/img/waiting.gif" alt="waiting"/>
      </div>
      <div class="d-flex justify-content-between" :class="lang==='he' ? 'text-right' : 'text-left'" v-else>
        <h5>{{ $t('booking.waiting-populating') }}</h5>
        <img src="/assets/img/waiting.gif" alt="waiting"/>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { loadProgressBar } from 'axios-progress-bar';
import 'axios-progress-bar/dist/nprogress.css';

export default {
  name: 'App',
  components: {
    Container: () => import('@/components/Container'),
    NoRegisterAgency: () => import('@/components/atoms/NoRegisterAgency'),
    ContentLoading: () => import('@/components/atoms/ContentLoading'),
    LandingPage: () => import('@/components/landing/LandingPage'),
    AgencyLogin: () => import('@/components/agencyLogin/login'),
  },
  metaInfo() {
    let title = '';
    const metaInfo = [];
    let slugInfo = null;
    const { name, meta, params: { slug } } = this.$route;
    const { destination, chain, dealType } = this.urlParams;
    switch (name) {
      case 'home':
        if (destination !== undefined) {
          title = `${this.baseName} מלונות ב${this.destinationName}`;
          metaInfo.push({ name: 'description', content: `מבצעים ודילים של ${this.baseName} מלונות ${this.destinationName}` });
        } else if (chain !== undefined) {
          title = `${this.baseName} מבצעים ודילים לרשת ${chain}`;
          metaInfo.push({ name: 'description', content: `${this.baseName}, מחירים מיוחדים ובלעדיים למלונות ברשת ${chain}` });
        } else if (dealType !== undefined) {
          title = 'מלונות בארץ';
        } else {
          title = `${this.baseName} מלונות בארץ`;
          metaInfo.push({ name: 'description', content: 'נופש באתר לבודדים במחירי ועדים, מלונות באילת, ים המלח, טבריה, תל אביב, ירושלים ועוד במחירים מצויינים ושרות מעולה' });
        }
        break;
      case 'product-page':
        title = `${this.hotelName} | השטיח המעופף מלון`;
        metaInfo.push({ name: 'description', content: `${this.baseName}, מחירי ועדים להזמנות בודדים במלון, ${this.hotelName} ${this.cityName}מבצעים ל ${this.hotelName} ${this.cityName}` });
        break;
      case 'booking':
        title = `${this.hotelName} ${this.cityName}תשלום | ${this.baseName} | מלון`;
        metaInfo.push({ name: 'description', content: `${this.hotelName} ${this.cityName}עמוד תשלום, חברת ${this.baseName}, מלון` });
        break;
      case 'search-result':
        if (this.privateMessage !== '') this.showAdminMessageForAgent(this.privateMessage, 'private');
        title = `השטיח המעופף תוצאות חיפוש ל${this.cityName}`;
        metaInfo.push({ name: 'description', content: `${this.cityName}הדילים הכי שווים עכשיו בשטיח המעופף. תוצאות חיפוש למלונות ב` });
        break;
      case 'booking-hotel':
        title = `${this.hotelName} השטיח המעופף הזמנת מלון`;
        metaInfo.push({ name: 'description', content: `${this.baseName} - חברת התיירות הגדולה בישראל. מבצעי נופש, דילים, כנסים, אירועים הכל תחת קורת גג אחת` });
        break;
      case 'hotels-page':
        title = `${this.cityName}${this.baseName} | מלונות ב`;
        metaInfo.push({ name: 'description', content: `באתר של ${this.baseName}. מחירי ועדים להזמנות בודדים. מחירים ${this.cityName}כל מלונות מיוחדים למלונות ${this.cityName} אצלנו באתר. ${this.baseName}, חברת התיירות הגדולה בישראל` });
        break;
      case 'verifying-card': case 'card-verified':
        title = `${this.baseName} תשלום מאובטח`;
        metaInfo.push({ name: 'description', content: `${this.baseName} - חברת התיירות הגדולה בישראל. מבצעי נופש, דילים, כנסים, אירועים הכל תחת קורת גג אחת` });
        break;
      case 'slug-info':
        if (this.footerPagesList) {
          // eslint-disable-next-line no-unused-vars
          Object.entries(this.footerPagesList).forEach(([key, slugItem]) => {
            if (slugItem.slug === slug) slugInfo = slugItem;
          });
          ({ title } = slugInfo.meta.title);
          metaInfo.push({ name: 'description', content: slugInfo.meta.description });
          metaInfo.push({ name: 'keywords', content: slugInfo.meta.keyword });
        }
        break;
      default:
        title = meta.title ? `${this.baseName} - ${meta.title}` : this.baseName;
    }
    if (this.marketerId !== '') {
      title = this.agencyMarketerContent?.metaTitle ?? title;
      // this.setBackgroundColor(this.agencyMarketerContent?.backgroundColorCss ?? 'white');
    } else if (this.whiteLabel && !this.whiteLabel.forMainSite) {
      title = this.whiteLabel?.metaTitle ?? title;
      // this.setBackgroundColor(this.whiteLabel?.backgroundColorCss ?? 'white');
    }

    if (!this.isRegularSite) {
      metaInfo.push({ name: 'description', content: this.metaDescriptionMarketer });
    }

    document.title = title;
    const origin = window.location.href;
    document.querySelector('head meta[property="og:url"]').setAttribute('content', origin);

    return {
      title,
      meta: metaInfo,
    };
  },
  data() {
    return {
      destinationImageList: [],
      isAgency: true,
      isFetchedAllData: false,
      marketerId: '',
    };
  },
  computed: {
    ...mapGetters({
      lang: 'GET_LANGUAGE',
      currentPage: 'GET_CURRENT_PAGE',
      metaInfo: 'GET_META_INFO',
      whiteLabel: 'GET_WHITE_LABEL_DATA',
      moreHotelInfo: 'GET_ONE_HOTEL_INFO',
      destinationList: 'GET_DOMESTIC_DESTINATION',
      isLanding: 'GET_IS_LANDING_PAGE',
      isLandingChain: 'GET_IS_LANDING_PAGE_CHAIN',
      landingInfo: 'GET_LANDING_INFO',
      footerPagesList: 'GET_FOOTER_PAGES_LIST',
      isAgencyAgentLogin: 'IS_AGENCY_AUTHORIZED',
      isAgencyLoginState: 'GET_AGENCY_LOGIN_STATE',
      isLoginMarketer: 'GET_IS_LOGIN_MARKETER',
      isOdysseySite: 'GET_ODYSSEY_AGENT_STATE',
      privateMessage: 'GET_PRIVATE_MESSAGE',
      sharedMessage: 'GET_SHARED_MESSAGE',
      errorBody: 'GET_ERROR_RESPONSE',
      isProcessingBooking: 'GET_PROCESSING_BOOKING_STATE',
      isEmalonMode: 'GET_IS_EMALON_MODE',
      isFCLAgent: 'IS_FCL_ODYSSEY_AGENT_CODE',
      agencyMarketerContent: 'GET_MARKETER_AGENCY_CONTENT',
    }),
    urlParams() {
      let match;
      const pl = /\+/g,
        search = /([^&=]+)=?([^&]*)/g,
        decode = (s) => decodeURIComponent(s.replace(pl, ' ')),
        query = window.location.search.substring(1);
      const urlParams = {};
      // eslint-disable-next-line no-cond-assign
      while ((match = search.exec(query))) urlParams[decode(match[1])] = decode(match[2]);

      return urlParams;
    },
    isActive() {
      return this.whiteLabel?.active;
    },
    hotelName() {
      const { moreHotelInfo } = this;
      if (!moreHotelInfo) return '';
      return moreHotelInfo?.hotelConversion?.hotelName || '';
    },
    cityName() {
      const { moreHotelInfo, destinationList } = this;
      if (!moreHotelInfo && !destinationList) return '';
      const mainCityCode = moreHotelInfo?.hotelConversion?.mainCityCode || this.$route.query.city || '';
      return (mainCityCode !== '') ? destinationList.find((item) => (item.code === mainCityCode))?.name : '';
    },
    destinationName() {
      const { destinationList } = this;
      const { destination } = this.urlParams;
      if (!destinationList) return '';
      const dest = destinationList.find((item) => (item.code === destination));
      return dest ? dest.name : '';
    },
    baseName() {
      return this.isEmalonMode ? 'אימלון' : 'השטיח המעופף';
    },
    backgroundColor() {
      let color = 'unset';
      if (this.marketerId !== '') {
        color = this.agencyMarketerContent?.backgroundColorCss ?? 'unset';
      }
      return color;
    },
    isRegularSite() {
      return this.marketerId === '' && this.whiteLabel !== null;
    },
    metaDescriptionMarketer() {
      let description = '';
      if (this.marketerId) {
        description = this.agencyMarketerContent?.metaDescription ?? '';
      } else {
        description = this.whiteLabel?.metaDescription ?? '';
      }
      return description;
    },
  },
  watch: {
    isAgencyLoginState() {
      if (this.isAgencyAgentLogin) {
        window.location.reload();
      }
    },
    isAgencyAgentLogin() {
      if (this.isAgencyAgentLogin) {
        setTimeout(() => {
          this.$store.dispatch('SOCKET_SHARED_CONNECT', this);
          this.$store.dispatch('SOCKET_PRIVATE_CONNECT', this);
        }, 1000);
      } else {
        this.$store.dispatch('SOCKET_DIS_CONNECT', this);
      }
    },
    privateMessage() {
      this.showAdminMessageForAgent(this.privateMessage, 'private');
    },
    sharedMessage() {
      this.showAdminMessageForAgent(this.sharedMessage, 'shared');
    },
    errorBody() {
      if (this.errorBody && this.errorBody !== null) {
        this.showAdminMessageForAgent(`${this.errorBody?.statusText ?? ''} </br> ${this.$t('home.error-message-text')}`, `Error Code: ${this.errorBody?.status ?? ''}'`);
      }
    },
    isProcessingBooking() {
      if (this.isProcessingBooking) {
        this.$bvModal.show('processingBooking');
      } else {
        this.$bvModal.hide('processingBooking');
      }
    },
  },
  methods: {
    resize() {
      let device = 'desktop';
      const { offsetWidth } = document.getElementById('app');
      if (offsetWidth < 479) {
        device = 'mobile';
      } else {
        device = 'desktop';
      }
      this.$store.dispatch('UPDATE_DEVICE', device);

      // set media size(Theme2 use this)
      let mediaSize = 'large-size';
      if (offsetWidth > 867) {
        mediaSize = 'large-size';
      } else {
        mediaSize = 'small-size';
      }
      this.$store.dispatch('UPDATE_MEDIA_SIZE', mediaSize);
    },
    async getSetSomeParameter() {
      const { dealType, subMarketerId, partnerId, marketerId, lang } = this.urlParams;

      // get marketerId
      // if (dealType) await this.$store.dispatch('FETCH_MARKETER_ID', dealType);
      // set MargeterId
      if (marketerId && marketerId !== '') this.$store.commit('SET_MARKETER_ID', marketerId);
      if (partnerId && partnerId !== '') this.$store.commit('SET_PARTNER_ID', partnerId);

      // set MarketerAgency
      // const marketerAgency = dealType;
      // if (marketerAgency) this.$store.commit('SET_AGENCY_FROM_MARKETER_ID', marketerAgency);
      // set SubAgency
      if (subMarketerId && subMarketerId !== '') this.$store.commit('SET_SUB_AGENCY', subMarketerId);
      // if (marketerId) await this.$store.dispatch('FETCH_AGENCY_FROM_MARKETER_ID');
      if (marketerId || dealType) await this.$store.dispatch('FETCH_AGENCY_CONTENT_FROM_MARKETER_ID');
      if (partnerId && partnerId !== '') await this.$store.dispatch('FETCH_PARTNER_CONTENT_FROM_PARTNER_ID');

      // set language
      await window.localStorage.setItem('language', lang || 'he');
      await this.$store.dispatch('UPDATE_LANGUAGE', lang || 'he');
    },
    showAdminMessageForAgent(messageBody, type) {
      const title = `${type === 'private' ? this.$t('common-message.private') : this.$t('common-message.share')}`;
      const h = this.$createElement;
      const messageContent = h('div', {
        domProps: {
          innerHTML: messageBody,
        },
      });
      const id = `${type}-${this.count += 1}`;
      const closeButton = h(
        'b-button',
        {
          props: { variant: 'primary', size: 'sm' },
          on: { click: () => this.$bvToast.hide(id) },
        },
        this.$t('common-message.close'),
      );
      this.$bvToast.toast([messageContent, closeButton], {
        id,
        title,
        variant: 'danger',
        solid: true,
        noAutoHide: true,
        toaster: 'b-toaster-top-center',
        bodyClass: ['text-right', 'message-content'],
        noCloseButton: true,
      });
    },
  },
  async created() {
    let { host } = window.location;
    if (window.location.href.indexOf('google') > -1) {
      this.$store.commit('SET_URL_CONTAIN_GOOGLE');
    } else if (window.location.href.indexOf('facebook') > -1) {
      this.$store.commit('SET_URL_CONTAIN_FACEBOOK');
    }

    window.gtag('js', new Date());
    if (window.location.href.indexOf('ga-debug=true') > -1 || host.includes('vercel') || host.includes('localhost')) {
      window.gtag('config', 'G-SGCF8SQQPY', { debug_mode: true });
    } else {
      window.gtag('config', 'G-SGCF8SQQPY');
    }

    if (window.location.pathname === '/') {
      this.$store.dispatch('STORE_IS_LANDING_STATE', this.urlParams);
    }
    window.addEventListener('resize', this.resize);

    if (host.includes('domestic-dev.vercel.app')) this.$store.commit('SET_STAGING_STATE', true);
    if (host.includes('domestic-staging.vercel.app')) this.$store.commit('SET_STAGING_STATE', true);
    if (host.includes('localhost')) this.$store.commit('SET_STAGING_STATE', true);
    if (host.includes('flyingcarpet.co.il')) this.$store.commit('SET_STAGING_STATE', false);
    if (host.includes('new')) this.$store.commit('SET_AZURE_STATE', true);

    if (host.includes('localhost') || host.includes('vercel')) host = 'www.flyingcarpet.co.il';
    // if (host.includes('localhost') || host.includes('vercel')) host = 'agent.flyingcarpet.co.il'; // agent site
    // if (host.includes('localhost') || host.includes('vercel')) host = 'www.emalon.co.il'; // for Emalon site

    const { marketerId } = this.urlParams;
    this.marketerId = marketerId;

    // get agency contents
    const response = await this.$store.dispatch('FETCH_WHITE_LABEL', host);
    const { data } = response;

    // check url parameters
    await this.getSetSomeParameter();

    if (!host.includes('agent') && (this.marketerId === undefined || this.marketerId === '')) {
      const gTagId = this.isEmalonMode ? 'GTM-K8XF8KL' : 'GTM-MLX2DQM';
      const gtagLayerScript = document.createElement('script');
      gtagLayerScript.setAttribute('id', 'google-tag-manager');
      gtagLayerScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gTagId}');`;
      document.head.appendChild(gtagLayerScript);
    }

    if (window.location.pathname === '/') {
      await this.$store.dispatch('STORE_IS_LANDING_STATE', this.urlParams);
    }
    const beforeLogin = window.sessionStorage.getItem('agencyLogin') === 'true';

    if (this.isOdysseySite || beforeLogin) await this.$store.dispatch('VERIFY_AGENCY_LOGIN_STATE');
    if (this.isOdysseySite && !this.isAgencyAgentLogin) return;

    if (data?.status !== 404) {
      await this.$store.dispatch('FETCH_HOTEL_CHAIN');
      await this.$store.dispatch('FETCH_HOTEL_CATEGORY');
      await this.$store.dispatch('FETCH_DOMESTIC_DESTINATION');
      await this.$store.dispatch('FETCH_STATIC_PAGE_SUBTYPE_LIST');
      this.$store.dispatch('FETCH_FOOTER_PAGES_LIST');
      await this.$store.dispatch('STORE_LANDING_URL', this.urlParams);
    } if (data?.status === 404) {
      this.isAgency = false;
    }
    this.isFetchedAllData = true;

    if (this.isFCLAgent) {
      const onsiteScript = document.createElement('script');
      onsiteScript.setAttribute('id', 'onsite');
      onsiteScript.innerHTML = `(function(d, t) {
            var g = d.createElement(t),
            s = d.getElementsByTagName(t)[0];
            g.src = "https://cdn.inwebr.com/inwebr_dcfc4e2dd12c91ef939d6dc745c5a952.js";
            s.parentNode.insertBefore(g, s);
        }(document, "script"));`;
      document.head.appendChild(onsiteScript);
    }
  },
  mounted() {
    this.resize();
    window.scrollTo(0, 0);
    loadProgressBar({ showSpinner: false });
    // this.$bvModal.show('processingBooking');
    window.addEventListener('beforeunload', () => {
      this.$store.dispatch('SOCKET_DIS_CONNECT', this);
    });
  },
  destroyed() {
    window.removeEventListener('resize', this.resize);
  },
};
</script>

<style lang="sass">
@import '~bootstrap/scss/bootstrap.scss'
@import '~bootstrap-vue/src/index.scss'
.agile
  &__nav-button
    background: #fff
    border: 1px solid #4285f4
    color: #4285f4
    cursor: pointer
    font-size: 20px
    height: 40px
    position: absolute
    top: calc(50% - 40px)
    transition-duration: .3s
    width: 40px
    border-radius: 50%
    transition: 0.5s all

    &:hover
      border: none
      color: black
      box-shadow: gray -1px 1px 10px
      opacity: 1

    &--prev
      left: 0

    &--next
      right: 0

  &__dots
    bottom: 10px
    left: 50%
    position: absolute
    transform: translateX(-50%)

  &__dot
    margin: 0 10px

    button
      background-color: transparent
      border: 1px solid #fff
      border-radius: 50%
      cursor: pointer
      display: block
      height: 10px
      font-size: 0
      line-height: 0
      margin: 0
      padding: 0
      transition-duration: .3s
      width: 10px

    &--current,
    &:hover
      button
        background-color: #fff
</style>

<style>
#app {
  font-family: "ploni", "Poppins", sans-serif;
  text-align: right;
  height: 100%;
}
#app.has-background {
  background-image: url('/assets/img/banner_landing.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}
html {
  scroll-behavior: smooth;
}
 #nprogress .bar {
  background: rgb(0, 255, 149) !important;
  height: 5px;
}
#nprogress .peg {
  box-shadow: 0 0 10px rgb(0, 255, 149), 0 0 5px rgb(0, 255, 149) !important;
}
#nprogress .spinner-icon {
  border-top-color: rgb(0, 255, 149) !important;
  border-left-color: rgb(0, 255, 149) !important;
}
.st-content{
  scroll-behavior: smooth;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
#processingBooking + .modal-backdrop {
  opacity: 1;
  background-color: white;
}
#processingBooking .modal-content {
  box-shadow: #cac8c8 1px 1px 20px;
}
#processingBooking.ltr {
  direction: ltr;
}
#processingBooking.rtl {
  direction: rtl;
}
#processingBooking .modal-body h5 {
  padding: 35px 20px;
  font-size: 30px;
}
#processingBooking h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
#processingBooking.ltr {
  direction: ltr;
}
#processingBooking.rtl {
  direction: rtl;
}
#processingBooking .modal-body h5 {
  padding: 35px 20px;
  font-size: 30px;
}
#processingBooking img {
  width: 200px;
}
#processingBooking img.product {
  width: 400px;
}
#processingBooking h3 {
  font-size: 22px;
  font-weight: 600;
  color: #313131;
  text-align: center;
}
#b-toaster-top-center {
  top: 70px;
}
#processingBooking.modal .modal-dialog {
  display: flex;
  font-family: 'ploni', 'Poppins', sans-serif;
  font-weight: 500;
}
#processingBooking.modal:before {
  display: none;
}
#processingBooking.modal .modal-body {
  padding: 0px;
  margin: 0px;
}
@media (max-width:479px){
  #processingBooking img {
    width: 100px;
    height: 100px;
  }
  #processingBooking img.product {
    width: 100%;
    height: 100%;
  }
  #processingBooking .modal-body h5 {
    font-size: 20px;
  }
}
</style>
